<template>
  <div>
    <b-row>
      <b-col md="6">
        <BasicInfoPanel></BasicInfoPanel>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header class="bg-success text-white">
            <i class="fas fa-heart"></i><span> サービス</span>
            <div class="pull-right">
              <ServiceEditModalButton @onUpdated="refresh"></ServiceEditModalButton>
            </div>
          </b-card-header>
          <b-card-body>
            <b-form horizontal>
              <b-form-group label="定員等" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <table class="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr class="text-center">
                      <th class="small">登録定員:</th>
                      <td class="text-center"><ServiceView :value="item.services.capacity1"></ServiceView>名</td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">通いサービスの利用定員:</th>
                      <td class="text-center"><ServiceView :value="item.services.capacity2"></ServiceView>名</td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">宿泊サービスの利用定員:</th>
                      <td class="text-center"><ServiceView :value="item.services.capacity3"></ServiceView>名</td>
                    </tr>
                  </tbody>
                </table>
              </b-form-group>
              <b-form-group label="営業日" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <BusinessDaysTable :half="true"></BusinessDaysTable>
              </b-form-group>
              <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light" body-class="px-2 py-auto">{{item.business_days.memo}}</b-card>
              </b-form-group>
            </b-form>
            <hr>
            <b-form horizontal>
              <b-form-group label="24時間連絡対応" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <table class="table table-bordered table-striped table-sm">
                  <thead>
                    <tr class="small">
                      <th>対応</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center"><ServiceView :value="item.services.service_for_24h"></ServiceView></td>
                      <td>
                        {{item.services.service_for_24h_memo}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class='help-block small'>
                  ※ ○：対応可能 △：応相談 ×：対応可能<br>
                  ※ 24時間の連絡の取れる体制の有無
                </p>
              </b-form-group>
              <b-form-group label="特定事業所加算" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <table class="table table-bordered table-striped table-sm">
                  <thead>
                    <tr class="small">
                      <th>有無</th>
                      <th>加算型</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td class="text-center"><ServiceView :value="item.services.medical_score"></ServiceView></td>
                      <td>
                        <span v-if="item.services.medical_score_class === '1'">I 型</span>
                        <span v-else-if="item.services.medical_score_class === '2'">II 型</span>
                        <span v-else-if="item.services.medical_score_class === '3'">III 型</span>
                        <span v-else>{{item.services.medical_score_class}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="help-block small">
                  ※ ◯：対応可能、△：応相談、☓：対応不可<br>
                  ※ 主任ケアマネの配置と複数のケアマネが所属している事業所で、他要件を満たしている事業所が算定できるものです。（要件によりⅠ～Ⅲがあり）
                </p>
                <table class="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr class="text-center">
                      <th class="small">サテライト体制</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_satellite"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">若年性認知症利用者受入加算</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_younger"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">緊急時訪問介護加算</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_emergency"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">特別管理体制</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_special"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">ターミナルケア体制</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_terminal"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">サービス提供体制強化加算</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_service"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">総合マネジメント体制強化加算</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_general"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">看護体制強化加算</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_nurse"></ServiceView></td>
                    </tr>
                    <tr class="text-center">
                      <th class="small">訪問体制強化加算</th>
                      <td class="text-center"><ServiceView :value="item.services.medical_score_visit"></ServiceView></td>
                    </tr>
                  </tbody>
                </table>
              </b-form-group>
              <b-form-group label="備考" label-cols="2" label-cols-lg="3" label-class="text-right font-weight-bold pr-3">
                <b-card bg-variant="light" body-class="px-2 py-auto">{{item.services.memo}}</b-card>
                <p class="small mt-2">※連絡する際の要望、注意点、病院の特定など、ケアマネージャーに伝えたいことなど</p>
              </b-form-group>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col lg="6">
        <googleMapComp></googleMapComp>
      </b-col>
      <b-col lg="6">
        <b-card header="更新履歴" header-class="bg-info text-white">
          <b-list-group>
            <b-list-group-item class="small">{{formatDate(item.created_at)}} 初期登録</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import BasicInfoPanel from '@/components/modules/BasicInfoPanelComp'
import BusinessDaysTable from '@/components/modules/BusinessDaysTableComp'
import ServiceEditModalButton from '@/components/modules/SmallMultiHomeNurseServiceEditModalButton'
import ServiceView from '@/components/modules/ServiceViewComp'
import googleMapComp from '@/components/modules/GoogleMapComp'

export default {
  name: 'homeVisitCareSupportServiceDetail',
  components: {
    BasicInfoPanel,
    BusinessDaysTable,
    ServiceEditModalButton,
    ServiceView,
    googleMapComp
  },
  data () {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'currentItem'
    ]),
    item: function () {
      return this.$store.getters.currentItem
    }
  },
  methods: {
    formatDate (value, fmt = 'YYYY/MM/DD') {
      return (value == null) ? '--' : moment(value, 'YYYY-MM-DD').format(fmt)
    },
    refresh () {
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.panel { box-shadow: 0 0 3px rgba(0,0,0,0.4); }
.form-group { margin-bottom: 5px; }
</style>
